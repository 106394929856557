import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../Header/Header";
import Contents from "../Pages/Contents";

const pageInfo = [
  {
    pageID: 0,
    pageName: "Calculate Software Cost",
    pageImagePath: "/engine/img/COCOMO/",
    pageGooglePlay: "com.geasis.yazilim_maliyet",
    pageDetailText:
      "Charge Your Customers Quickly and Easily.",
  },
  {
    pageID: 1,
    pageName: "Infinite Block",
    pageImagePath: "/engine/img/InfiniteBlock/",
    pageGooglePlay: "com.Geasis.InfiniteBlock",
    pageDetailText:
      "In the depths of the endless expanse, you gain a relaxing experience by blasting the blocks",
  },
  {
    pageID: 2,
    pageName: "Witcher Hero: Halloween",
    pageImagePath: "/engine/img/WitcherHero/",
    pageGooglePlay: "com.Geasis.WitcherHero",
    pageDetailText:
      "Witch is back, and stronger than ever!",
  },
  {
    pageID: 3,
    pageName: "Spin And Run",
    pageImagePath: "/engine/img/SpinAndRun/",
    pageGooglePlay: "com.Geasis.SpinAndRun",
    pageDetailText:
      "Use your spinner to pop balloons and avoid obstacles. Hyper casual fun!",
  },
  {
    pageID: 4,
    pageName: "Garage Race",
    pageImagePath: "/engine/img/GarageRace/",
    pageGooglePlay: "com.Geasis.GarageRace",
    pageDetailText:
      "Fuel up, Nitro on! Garage Race awaits!",
  },
  {
    pageID: 5,
    pageName: "Find Alien",
    pageImagePath: "/engine/img/FindAlien/",
    pageGooglePlay: "com.Geasis.FindAlien",
    pageDetailText:
      "Spot the hidden Alien in this challenging puzzle game",
  },
];
class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageID: 0,
    };
  }
  handleProps() {
    let pageProp = JSON.parse(localStorage.getItem("selectedStar"));
    if (pageProp.pageID > pageInfo.length - 1) {
      pageProp.pageID = 0;
    }
    if (pageProp.pageID !== this.state.pageID) {
      this.setState({
        pageID: pageProp.pageID,
      });
    }
  }

  componentDidMount() {
    setInterval(() => {
      this.handleProps();
    }, 500);
  }
  render() {
    return (
      <section className="pageBody hide">
        <section className="pageMain container align-items-center">
          <Header pageName={pageInfo[this.state.pageID].pageName} />
          <Contents pageInfo={pageInfo[this.state.pageID]} />
        </section>
      </section>
    );
  }
}

export default Pages;

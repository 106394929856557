import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Slider from "./Slider";
import Card from "./Card";
class Contents extends React.Component {
  render() {
    let pageInfo = this.props.pageInfo;

    return (
      <section className="pageContent">
        {/* <section>{pageInfo.pageID}</section>
        <section>{pageInfo.pageImagePath}</section>
        <section>{pageInfo.pageGooglePlay}</section>
        <section>{pageInfo.pageDetailText}</section> */}

        <section className="sliderSection">
          <section className="sliderSectionCenter">
            <Slider pageID={pageInfo.pageID} />
          </section>
        </section>

        <section className="pageInfo">
          <section className="row justify-content-md-center justify-content-sm-center">
            <section className="col-sm-10 col-md-6 col-lg-4">
              <Card
                bgPath={pageInfo.pageImagePath}
                detailText={pageInfo.pageDetailText}
              />
            </section>
            <section className="col-sm-10 col-md-6 col-lg-4">
              <Card
                bgPath={pageInfo.pageImagePath}
                googlePlay={pageInfo.pageGooglePlay}
              />
            </section>
          </section>
        </section>
      </section>
    );
  }
}

export default Contents;

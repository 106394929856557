import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaWindowClose } from "react-icons/fa";

class Header extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <header className="pageHeader">
        <section className="pageTitle">
          <span>{this.props.pageName}</span>
        </section>
        <div className="pageHeaderDetail">
          <div className="pageDetailColounmZone">
            <div className="pageHeaderDetailCoulum LeftCol"></div>
            <div className="pageHeaderDetailCoulum LeftCol"></div>
            <div className="pageHeaderDetailCoulum LeftCol"></div>
            <div className="pageHeaderDetailCoulum RightCol"></div>
            <div className="pageHeaderDetailCoulum RightCol"></div>
            <div className="pageHeaderDetailCoulum RightCol"></div>
          </div>
          <div className="pageHeaderButtonSection">
            <div className="btn-group" role="group">
              <button
                type="button"
                className="btn btnClosePage colorGradiend-BloodRed"
              >
                <FaWindowClose />
              </button>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;

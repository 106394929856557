import React from "react";
import $ from "jquery";
import ReactTooltip from "react-tooltip";

const colorGradiendList = [
  ["colorGradiend-SweetAiran", "white"],
  ["colorGradiend-DeLacros", "white"],
  ["colorGradiend-SmallBusiness", "blue"],
  ["colorGradiend-UnexpectedErection", "blue"],
  ["colorGradiend-AdelesSecondLove", "green"],
  ["colorGradiend-Soda", "orange"],
  ["colorGradiend-SuperMarket", "orange"],
];

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

function Filters() {
  let baseFrequency = getRandomNumber(0.005, 0.01);
  let numOctaves = Math.round(getRandomNumber(2, 50));
  let seed = Math.round(getRandomNumber(200, 1000));
  let scale = Math.round(getRandomNumber(100, 250));
  return (
    <svg width="0" height="0" style={{ display: "none" }}>
      <filter id="filter-mid">
        <feTurbulence
          type="fractalNoise"
          baseFrequency={baseFrequency}
          numOctaves={numOctaves}
          seed={seed}
        />
        <feDisplacementMap in="SourceGraphic" scale={scale} />
      </filter>
      <filter id="wavy">
        <feTurbulence x="0" y="0" baseFrequency="0.09" numOctaves="5" speed="2">
          <animate
            attributeName="baseFrequency"
            dur="60s"
            values="0.02; 0.005; 0.02"
            repeatCount="indefinite"
          />
        </feTurbulence>
        <feDisplacementMap in="SourceGraphic" scale="40" />
      </filter>
    </svg>
  );
}

function SpaceDust() {
  let winWid = window.innerWidth;
  let winHeig = window.innerHeight;

  let size = getRandomNumber(winWid / 3.84, winWid / 2.6);

  let pos_X = getRandomNumber(-290, winWid - size - 300);
  let pos_Y = getRandomNumber(-330, winHeig - 275 - 340);

  let pr1 = "300px "; //Left for boxshadow
  let pr2 = "340px "; //Top for boxshadow
  let pr3 = Math.round(getRandomNumber(50, 100)) + "px ";
  let pr4 = Math.round(getRandomNumber(1, 10)) + "px ";

  let red = Math.round(getRandomNumber(30, 220));
  let green = Math.round(getRandomNumber(30, 220));
  let blue = Math.round(getRandomNumber(30, 220));

  let boxShadowPR =
    pr1 +
    pr2 +
    pr3 +
    pr4 +
    " rgba(" +
    red +
    ", " +
    green +
    ", " +
    blue +
    ", 1)";
  return (
    <div
      className="cloud"
      data-posx={pos_X}
      data-posy={pos_Y}
      style={{
        top: pos_Y,
        left: pos_X,
        width: size,
        boxShadow: boxShadowPR,
        opacity: "0.35",
      }}
    ></div>
  );
}

let navStarPos = [];

function randomPos() {
  let pos_X = getRandomNumber(200, window.outerWidth - 200);
  let pos_Y = getRandomNumber(200, window.outerHeight - 200);
  return [pos_X, pos_Y];
}

function NavStarsGenerator(props) {
  let contunie = true;
  let poss = [];
  while (true) {
    poss = randomPos();
    for (var i = 0; i < navStarPos.length; i++) {
      let arrPos = navStarPos;
      let arrX = arrPos[0];
      let arrY = arrPos[1];
      let control =
        poss[0] >= arrX - 15 &&
        poss[0] <= arrX + 15 &&
        poss[1] >= arrY - 15 &&
        poss[1] <= arrY + 15;
      if (control) {
        contunie = false;
        break;
      }
    }

    if (contunie) {
      break;
    }
  }

  navStarPos.push(poss);

  let pos_X = poss[0];
  let pos_Y = poss[1];
  let selectedGrad =
    colorGradiendList[Math.floor(Math.random() * colorGradiendList.length)];
  let gradiend = selectedGrad[0];
  let gradTag = selectedGrad[1];
  let className = "stars navStar " + gradiend;
  return (
    <div
      className={className}
      style={{
        top: pos_Y,
        left: pos_X,
      }}
      data-posx={pos_X}
      data-posy={pos_Y}
      data-grad={gradTag}
      data-id={props.pageID}
      data-tip
      data-for={"navStarToolTip" + props.pageID}
    >
      <ReactTooltip
        id={"navStarToolTip" + props.pageID}
        place="top"
        effect="solid"
      >
        {props.pageName}
      </ReactTooltip>
    </div>
  );
}

class Stars extends React.Component {
  componentDidMount() {
    if (!window.clickable) {
      window.clickable = true;
    }

    if (!window.mouseActive) {
      window.mouseActive = false;
    }
    if (!window.mouseDown) {
      window.mouseDown = false;
    }

    function WarpDrive(element, params) {
      var settings = {};
      settings.width = window.outerWidth;
      settings.height = window.outerHeight;
      settings.autoResize = false;
      settings.autoResizeMinWidth = null;
      settings.autoResizeMaxWidth = null;
      settings.autoResizeMinHeight = null;
      settings.autoResizeMaxHeight = null;
      settings.addMouseControls = true;
      settings.addTouchControls = true;
      settings.hideContextMenu = true;
      settings.starCount = 6666;
      settings.starBgCount = 2222;
      settings.starBgColor = { r: 255, g: 255, b: 255 };
      settings.starBgColorRangeMin = 10;
      settings.starBgColorRangeMax = 40;
      settings.starColor = { r: 255, g: 255, b: 255 };
      settings.starColorRangeMin = 10;
      settings.starColorRangeMax = 100;
      settings.starfieldBackgroundColor = { r: 0, g: 0, b: 0 };
      settings.starDirection = 1;
      settings.starSpeed = 0;
      settings.starSpeedMax = 100;
      settings.starSpeedAnimationDuration = 2;
      settings.starFov = 300;
      settings.starFovMin = 200;
      settings.starFovAnimationDuration = 2;
      settings.starRotationPermission = true;
      settings.starRotationDirection = 1;
      settings.starRotationSpeed = 0.0;
      settings.starRotationSpeedMax = 1.0;
      settings.starRotationAnimationDuration = 2;
      settings.starWarpLineLength = 2.0;
      settings.starWarpTunnelDiameter = 100;
      settings.starFollowMouseSensitivity = 0.025;
      settings.starFollowMouseXAxis = true;
      settings.starFollowMouseYAxis = true;

      //---

      for (var prop in settings) {
        if (settings.hasOwnProperty(prop) && prop.indexOf("Duration") > -1) {
          settings[prop] = settings[prop] * 60;
        }
      }

      if (
        typeof settings.starBgColor === "string" &&
        settings.starBgColor.indexOf("#") > -1
      ) {
        settings.starBgColor = hexToRgb(settings.starBgColor);
      } else if (
        typeof settings.starBgColor === "string" &&
        settings.starBgColor.indexOf("rgb") > -1
      ) {
        settings.starBgColor = parseRGBString(settings.starBgColor);
      }

      if (
        typeof settings.starColor === "string" &&
        settings.starColor.indexOf("#") > -1
      ) {
        settings.starColor = hexToRgb(settings.starColor);
      } else if (
        typeof settings.starColor === "string" &&
        settings.starColor.indexOf("rgb") > -1
      ) {
        settings.starColor = parseRGBString(settings.starColor);
      }

      if (
        typeof settings.starfieldBackgroundColor === "string" &&
        settings.starfieldBackgroundColor.indexOf("#") > -1
      ) {
        settings.starfieldBackgroundColor = hexToRgb(
          settings.starfieldBackgroundColor
        );
      } else if (
        typeof settings.starfieldBackgroundColor === "string" &&
        settings.starfieldBackgroundColor.indexOf("rgb") > -1
      ) {
        settings.starfieldBackgroundColor = parseRGBString(
          settings.starfieldBackgroundColor
        );
      }

      if (!element) {
        throw Error("No div element found");
      }

      //---

      var MATHPI180 = Math.PI / 180;

      var canvasWidth = settings.width;
      var canvasHeight = settings.height;

      var starCount = settings.starCount;
      var starBgCount = settings.starBgCount;

      var starBgColor = settings.starBgColor;
      var starBgColorRangeMin = settings.starBgColorRangeMin;
      var starBgColorRangeMax = settings.starBgColorRangeMax;
      var starColor = settings.starColor;
      var starColorRangeMin = settings.starColorRangeMin;
      var starColorRangeMax = settings.starColorRangeMax;

      var starfieldBackgroundColor = settings.starfieldBackgroundColor;

      var starDirection = settings.starDirection;

      var starSpeed = settings.starSpeed;
      var starSpeedMin = starSpeed;
      var starSpeedMax = settings.starSpeedMax;
      var starSpeedAnimationDuration = settings.starFovAnimationDuration;
      var starSpeedAnimationTime = 0;

      var starFov = settings.starFov;
      var starFovMin = settings.starFovMin;
      var starFovMax = starFov;
      var starFovAnimationDuration = settings.starFovAnimationDuration;
      var starFovAnimationTime = starFovAnimationDuration;

      var starRotation = 0.0;
      var starRotationPermission = settings.starRotationPermission;
      var starRotationDirection = settings.starRotationDirection;
      var starRotationSpeed = settings.starRotationSpeed;
      var starRotationSpeedMin = starRotationSpeed;
      var starRotationSpeedMax = settings.starRotationSpeedMax;
      var starRotationAnimationDuration =
        settings.starRotationAnimationDuration;
      var starRotationAnimationTime = 0;

      var starWarpLineLength = settings.starWarpLineLength;
      var starWarpTunnelDiameter = settings.starWarpTunnelDiameter;
      var starFollowMouseSensitivity = settings.starFollowMouseSensitivity;
      var starFollowMouseXAxis = settings.starFollowMouseXAxis;
      var starFollowMouseYAxis = settings.starFollowMouseYAxis;
      var starDistance = 8000;

      var starBorderFront = -starFovMin + 1;
      var starBorderBack = starDistance;

      var starHolder;
      var starBgHolder;
      var starColorLookupTable;
      var starBgColorLookupTable;

      var canvas, ctx, imageData, pix;

      var center;

      var paused = false;

      var animFrameId;

      //---

      function init() {
        canvas = document.createElement("canvas");
        canvas.style.backgroundColor = rgbToHex(
          starfieldBackgroundColor.r,
          starfieldBackgroundColor.g,
          starfieldBackgroundColor.b
        );
        canvas.setAttribute("width", canvasWidth);
        canvas.setAttribute("height", canvasHeight);

        if (settings.hideContextMenu) {
          canvas.oncontextmenu = function (e) {
            e.preventDefault();
          };
        }

        $(element).append(canvas);

        //---

        ctx = canvas.getContext("2d");
        imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
        pix = imageData.data;

        //---

        center = { x: canvas.width / 2, y: canvas.height / 2 };

        window.mousePos = { x: center.x, y: center.y };

        //---

        starColorLookupTable = [];
        starBgColorLookupTable = [];
        starHolder = [];
        starBgHolder = [];

        addColorLookupTable(
          starColorLookupTable,
          starColorRangeMin,
          starColorRangeMax,
          starfieldBackgroundColor,
          starColor
        );
        addColorLookupTable(
          starBgColorLookupTable,
          starBgColorRangeMin,
          starBgColorRangeMax,
          starfieldBackgroundColor,
          starBgColor
        );
        addStars();
        animloop();

        //---

        if (settings.autoResize) {
          window.addEventListener("resize", resizeHandler);

          resize();
        }
      }

      //---

      Math.easeInQuad = function (t, b, c, d) {
        return (c * t * t) / (d * d) + b;
      };

      Math.easeOutQuad = function (t, b, c, d) {
        return (-c * t * t) / (d * d) + (2 * c * t) / d + b;
      };

      function shadeBlend(p, c0, c1) {
        var n = p < 0 ? p * -1 : p,
          u = Math.round,
          w = parseInt;
        var f = w(c0.slice(1), 16),
          t = w((c1 ? c1 : p < 0 ? "#000000" : "#FFFFFF").slice(1), 16),
          R1 = f >> 16,
          G1 = (f >> 8) & 0x00ff,
          B1 = f & 0x0000ff;

        return (
          "#" +
          (
            0x1000000 +
            (u(((t >> 16) - R1) * n) + R1) * 0x10000 +
            (u((((t >> 8) & 0x00ff) - G1) * n) + G1) * 0x100 +
            (u(((t & 0x0000ff) - B1) * n) + B1)
          )
            .toString(16)
            .slice(1)
        );
      }

      function hexToRgb(hex) {
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result
          ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
            }
          : null;
      }

      function rgbToHex(r, g, b) {
        var rgb = b | (g << 8) | (r << 16);

        return "#" + (0x1000000 + rgb).toString(16).slice(1);
      }

      //---

      function parseRGBString(rgbString) {
        rgbString = rgbString.replace(/\s+/g, "");

        var rgbValues = rgbString.split("(")[1].split(")")[0].split(",");

        return { r: rgbValues[0], g: rgbValues[1], b: rgbValues[2] };
      }

      //---

      function clearImageData() {
        for (var i = 0, l = pix.length; i < l; i += 4) {
          pix[i] = starfieldBackgroundColor.r;
          pix[i + 1] = starfieldBackgroundColor.g;
          pix[i + 2] = starfieldBackgroundColor.b;
          pix[i + 3] = 0;
        }
      }

      function setPixelAdditive(x, y, r, g, b, a) {
        var i = (x + y * canvasWidth) * 4;

        pix[i] = pix[i] + r;
        pix[i + 1] = pix[i + 1] + g;
        pix[i + 2] = pix[i + 2] + b;
        pix[i + 3] = a;
      }

      //---

      function drawLine(x1, y1, x2, y2, r, g, b, a) {
        var dx = Math.abs(x2 - x1);
        var dy = Math.abs(y2 - y1);

        var sx = x1 < x2 ? 1 : -1;
        var sy = y1 < y2 ? 1 : -1;

        var err = dx - dy;

        var lx = x1;
        var ly = y1;

        while (true) {
          if (lx > -1 && lx < canvasWidth && ly > -1 && ly < canvasHeight) {
            setPixelAdditive(lx, ly, r, g, b, a);
          }

          if (lx === x2 && ly === y2) break;

          var e2 = 2 * err;

          if (e2 > -dx) {
            err -= dy;
            lx += sx;
          }

          if (e2 < dy) {
            err += dx;
            ly += sy;
          }
        }
      }

      //---

      function addColorLookupTable(
        colorLookupTable,
        colorRangeMin,
        colorRangeMax,
        colorRGBStart,
        colorRGBEnd
      ) {
        var colorHexStart = rgbToHex(
          colorRGBStart.r,
          colorRGBStart.g,
          colorRGBStart.b
        );
        var colorHexEnd = rgbToHex(colorRGBEnd.r, colorRGBEnd.g, colorRGBEnd.b);

        var colorRange = [];
        var colorEndValues = [];

        var percent;

        var i, l, j, k;

        for (i = 0, l = 100; i <= l; i++) {
          percent = i / 100;

          colorRange.push(shadeBlend(percent, colorHexStart, colorHexEnd));
        }

        for (i = 0, l = colorRangeMax - colorRangeMin; i <= l; i++) {
          var index = i + colorRangeMin;

          colorEndValues.push(colorRange[index]);
        }

        for (i = 0, l = colorEndValues.length; i < l; i++) {
          colorRange = [];

          for (j = 0, k = 100; j <= k; j++) {
            percent = j / 100;

            colorRange.push(
              hexToRgb(shadeBlend(percent, colorHexStart, colorEndValues[i]))
            );
          }

          colorLookupTable.push(colorRange);
        }
      }

      //---

      function getStarPosition(radius, sideLength) {
        var x = Math.random() * sideLength - sideLength / 2;
        var y = Math.random() * sideLength - sideLength / 2;

        if (radius > 0) {
          while (Math.sqrt(x * x + y * y) < radius) {
            x = Math.random() * sideLength - sideLength / 2;
            y = Math.random() * sideLength - sideLength / 2;
          }
        }

        return { x: x, y: y };
      }

      function addStar(x, y, z, ox, oy, oz) {
        var star = {};
        star.x = x;
        star.y = y;
        star.z = z;
        star.ox = ox;
        star.oy = oy;
        star.x2d = 0;
        star.y2d = 0;

        return star;
      }

      function addStars() {
        var i;

        var x, y, z;

        var star, starPosition;

        for (i = 0; i < starBgCount; i++) {
          starPosition = getStarPosition(0, 20000);

          x = starPosition.x;
          y = starPosition.y;
          z = Math.round(Math.random() * starDistance);

          star = addStar(x, y, z, x, y, z);
          star.colorIndex = Math.floor(
            Math.random() * starBgColorLookupTable.length
          );
          star.colorLookupTable = starBgColorLookupTable[star.colorIndex];
          star.color = star.colorLookupTable[Math.floor(Math.random() * 100)];

          starBgHolder.push(star);
        }

        for (i = 0; i < starCount; i++) {
          starPosition = getStarPosition(starWarpTunnelDiameter, 10000);

          x = starPosition.x;
          y = starPosition.y;
          z = Math.round(Math.random() * starDistance);

          star = addStar(x, y, z, x, y, z);
          star.distance = starDistance - z;
          star.distanceTotal = Math.round(starDistance + starFov);
          star.colorIndex = Math.floor(
            Math.random() * starColorLookupTable.length
          );
          star.colorLookupTable = starColorLookupTable[star.colorIndex];
          star.color =
            star.colorLookupTable[
              Math.floor((star.distance / star.distanceTotal) * 100)
            ];

          starHolder.push(star);
        }
      }

      //---

      window.requestAnimFrame = (function () {
        return (
          window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.msRequestAnimationFrame ||
          function (callback) {
            window.setTimeout(callback, 1000 / 60);
          }
        );
      })();

      window.cancelAnimFrame = (function () {
        return (
          window.cancelAnimationFrame ||
          window.webkitCancelAnimationFrame ||
          window.mozCancelAnimationFrame
        );
      })();

      function animloop() {
        animFrameId = window.requestAnimFrame(animloop);

        if (!paused) {
          render();
        }
      }

      //---

      function render() {
        clearImageData();
        var i, l;

        //---

        var star;
        var scale;

        //---

        if (!window.clickable) {
          return;
        }

        if (starRotationPermission) {
          if (window.mouseDown) {
            starRotationAnimationTime += 1;

            if (starRotationAnimationTime > starRotationAnimationDuration)
              starRotationAnimationTime = starRotationAnimationDuration;
          } else {
            starRotationAnimationTime -= 1;

            if (starRotationAnimationTime < 0) starRotationAnimationTime = 0;
          }

          starRotationSpeed = Math.easeOutQuad(
            starRotationAnimationTime,
            starRotationSpeedMin,
            starRotationSpeedMax,
            starRotationAnimationDuration
          );
          starRotation -= starRotationSpeed * starRotationDirection;
        }

        //---
        if (window.mouseActive) {
          starSpeedAnimationTime += 1;

          if (starSpeedAnimationTime > starSpeedAnimationDuration)
            starSpeedAnimationTime = starSpeedAnimationDuration;

          starFovAnimationTime -= 1;

          if (starFovAnimationTime < 0) starFovAnimationTime = 0;

          //---

          if (starFollowMouseXAxis) {
            center.x +=
              (window.mousePos.x - center.x) * starFollowMouseSensitivity;
          }

          if (starFollowMouseYAxis) {
            center.y +=
              (window.mousePos.y - center.y) * starFollowMouseSensitivity;
          }
        } else {
          starSpeedAnimationTime -= 1;

          if (starSpeedAnimationTime < 0) starSpeedAnimationTime = 0;

          starFovAnimationTime += 1;

          if (starFovAnimationTime > starFovAnimationDuration)
            starFovAnimationTime = starFovAnimationDuration;

          //---

          if (starFollowMouseXAxis) {
            center.x +=
              (canvas.width / 2 - center.x) * starFollowMouseSensitivity;
          }

          if (starFollowMouseYAxis) {
            center.y +=
              (canvas.height / 2 - center.y) * starFollowMouseSensitivity;
          }
        }

        starSpeed =
          Math.easeOutQuad(
            starSpeedAnimationTime,
            0,
            starSpeedMax - starSpeedMin,
            starSpeedAnimationDuration
          ) + starSpeedMin;
        starFov =
          Math.easeInQuad(
            starFovAnimationTime,
            0,
            starFovMax - starFovMin,
            starFovAnimationDuration
          ) + starFovMin;

        //---

        starBorderFront = -starFov + 1;

        //---

        var warpSpeedValue =
          starSpeed * (starSpeed / (starSpeedMax / starWarpLineLength));

        //---
        //stars bg

        for (i = 0, l = starBgHolder.length; i < l; i++) {
          star = starBgHolder[i];

          scale = starFov / (starFov + star.z);

          star.x2d = star.x * scale + center.x;
          star.y2d = star.y * scale + center.y;

          if (
            star.x2d > -1 &&
            star.x2d < canvasWidth &&
            star.y2d > -1 &&
            star.y2d < canvasHeight
          ) {
            setPixelAdditive(
              star.x2d | 0,
              star.y2d | 0,
              star.color.r,
              star.color.g,
              star.color.b,
              255
            );
          }
        }

        //---
        //stars moving

        for (i = 0, l = starHolder.length; i < l; i++) {
          star = starHolder[i];

          //---
          //star distance calc

          star.distanceTotal = Math.round(starDistance + starFov);

          //---
          //star movement

          if (starDirection >= 0) {
            star.z -= starSpeed;
            star.distance += starSpeed;

            if (star.z < starBorderFront) {
              star.z = starBorderBack;
              star.distance = 0;
            }
          } else {
            star.z += starSpeed;
            star.distance -= starSpeed;

            if (star.z > starBorderBack) {
              star.z = starBorderFront;
              star.distance = star.distanceTotal;
            }
          }

          //---
          //star color

          star.color =
            star.colorLookupTable[
              Math.floor((star.distance / star.distanceTotal) * 100)
            ];

          //---
          //star position & draw

          scale = starFov / (starFov + star.z);

          star.x2d = star.x * scale + center.x;
          star.y2d = star.y * scale + center.y;

          if (starSpeed === starSpeedMin) {
            if (
              star.x2d > -1 &&
              star.x2d < canvasWidth &&
              star.y2d > -1 &&
              star.y2d < canvasHeight
            ) {
              setPixelAdditive(
                star.x2d | 0,
                star.y2d | 0,
                star.color.r,
                star.color.g,
                star.color.b,
                255
              );
            }
          } else {
            var nz = star.z + warpSpeedValue;

            scale = starFov / (starFov + nz);

            var x2d = star.x * scale + center.x;
            var y2d = star.y * scale + center.y;

            if (
              x2d > -1 &&
              x2d < canvasWidth &&
              y2d > -1 &&
              y2d < canvasHeight
            ) {
              drawLine(
                star.x2d | 0,
                star.y2d | 0,
                x2d | 0,
                y2d | 0,
                star.color.r,
                star.color.g,
                star.color.b,
                255
              );
            }
          }

          //---
          //star rotation

          if (starRotationSpeed !== starRotationSpeedMin) {
            var radians = MATHPI180 * starRotation;

            var cos = Math.cos(radians);
            var sin = Math.sin(radians);

            star.x = cos * star.ox + sin * star.oy;
            star.y = cos * star.oy - sin * star.ox;
          }
        }

        //---

        ctx.putImageData(imageData, 0, 0);
      }

      function resizeHandler(event) {
        resize();
      }

      function resize() {
        canvasWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        canvasHeight =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;

        if (
          settings.autoResizeMinWidth &&
          canvasWidth < settings.autoResizeMinWidth
        ) {
          canvasWidth = settings.autoResizeMinWidth;
        } else if (
          settings.autoResizeMaxWidth &&
          canvasWidth > settings.autoResizeMaxWidth
        ) {
          canvasWidth = settings.autoResizeMaxWidth;
        }

        if (
          settings.autoResizeMinHeight &&
          canvasHeight < settings.autoResizeMinHeight
        ) {
          canvasHeight = settings.autoResizeMinHeight;
        } else if (
          settings.autoResizeMaxHeight &&
          canvasHeight > settings.autoResizeMaxHeight
        ) {
          canvasHeight = settings.autoResizeMaxHeight;
        }

        //---

        canvas.setAttribute("width", canvasWidth);
        canvas.setAttribute("height", canvasHeight);

        center = { x: canvas.width / 2, y: canvas.height / 2 };

        //---

        imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
        pix = imageData.data;
      }

      //---

      this.pause = function () {
        paused = true;
      };

      this.unpause = function () {
        paused = false;
      };

      //---

      function destroy() {
        window.cancelAnimFrame(animFrameId);

        if (settings.autoResize) {
          window.removeEventListener("resize", resizeHandler);
        }

        clearImageData();

        if (element.hasChildNodes()) {
          element.removeChild(canvas);
        }

        starColorLookupTable = [];
        starBgColorLookupTable = [];
        starHolder = [];
        starBgHolder = [];
      }

      this.destroy = destroy;

      //---

      init();
    }

    window.WarpDrive = WarpDrive;

    new WarpDrive(document.getElementsByClassName("starField"));

    function styleToPos(elem) {
      let styleAttr = elem.getAttribute("style").trim().split(";");
      let topAttr = styleAttr[0].replace("top:", "").replace("px", "").trim();
      let leftAttr = styleAttr[1].replace("left:", "").replace("px", "").trim();
      let pos = {
        x: leftAttr,
        y: topAttr,
      };
      return pos;
    }

    function navStarClicked(event) {
      window.selectedStar = event.target;
      window.mousePos = styleToPos(window.selectedStar);
      window.mouseActive = true;
      window.mouseDown = true;
      let halfX = window.innerWidth / 2;
      let halfY = window.innerHeight / 2;
      let destX = halfX - window.mousePos.x;
      let destY = halfY - window.mousePos.y;

      $(".navStar,.cloud").each(function (index, element) {
        let currPos = styleToPos(element);
        let top = parseInt(destY) + parseInt(currPos.y);
        let left = parseInt(destX) + parseInt(currPos.x);
        $(element).animate(
          {
            top: top + "px",
            left: left + "px",
          },
          500
        );

        if (element !== event.target) {
          setTimeout(function () {
            $(element).animate(
              {
                opacity: 0,
              },
              1000,
              function () {
                $(element).css("visibility", "hidden");
              }
            );
          }, 300);
        }
      });
      setTimeout(function () {
        $(event.target).addClass(
          "navStarHasArrive-" + $(event.target).attr("data-grad")
        );
      }, 2000);
      $(event.target).animate(
        {
          width: window.innerHeight + "px",
          height: window.innerHeight + "px",
          top: "0px",
          left: "-" + window.innerHeight / 1.2 + "px",
        },
        2500,
        function () {
          window.clickable = false;
        }
      );

      setInterval(function () {
        window.mousePos = styleToPos(window.selectedStar);
      }, 100);
      setTimeout(function () {
        window.mouseActive = false;
        window.mouseDown = false;
      }, 1000);
      setTimeout(function () {
        $(".pageBody").removeClass("hide");
        $(".pageBody").animate(
          {
            opacity: 1,
          },
          1000
        );
      }, 1500);

      var testObject = {
        pageID: $(event.target).attr("data-id"),
      };
      localStorage.setItem("selectedStar", JSON.stringify(testObject));
    }

    $(document).on("click", ".navStar:not(.notclickable)", function (event) {
      $(".navStar").each(function (index, element) {
        element.classList.add("notclickable");
      });

      if (!window.clickable) {
        return;
      }
      navStarClicked(event);
    });

    $(document).on("click", ".btnClosePage", function (event) {
      $(".pageBody").addClass("hide");
      $(".pageBody").animate(
        {
          opacity: 0,
        },
        1000
      );
      window.clickable = true;
      window.mouseActive = true;
      window.mouseDown = true;
      setTimeout(function () {
        window.mouseActive = false;
        window.mouseDown = false;
      }, 1000);

      $(window.selectedStar).animate(
        {
          width: "24px",
          height: "24px",
        },
        500
      );
      $(".navStar,.cloud").each(function (index, element) {
        setTimeout(function () {
          $(element).animate(
            {
              opacity: 1,
            },
            1000,
            function () {
              $(element).css("visibility", "visible");
            }
          );
        }, 300);
        let top = $(element).attr("data-posy");
        let left = $(element).attr("data-posx");
        $(element).animate(
          {
            top: top + "px",
            left: left + "px",
          },
          500
        );
      });
      $(".navStar").each(function (index, element) {
        element.classList.remove("notclickable");
      });
      $(window.selectedStar).removeClass(
        "navStarHasArrive-" + $(window.selectedStar).attr("data-grad")
      );
    });
  }
  render() {
    return (
      <div className="starField">
        {<NavStarsGenerator pageID="0" pageName="Calculate Software Cost" />}
        {<NavStarsGenerator pageID="1" pageName="Infinite Block" />}
        {<NavStarsGenerator pageID="2" pageName="Witcher Hero: Halloween" />}
        {<NavStarsGenerator pageID="3" pageName="Spin And Run" />}
        {<NavStarsGenerator pageID="4" pageName="Garage Race" />}
        {<NavStarsGenerator pageID="5" pageName="Find Alien" />}
        {<SpaceDust />}
        {<Filters />}
      </div>
    );
  }
}

export default Stars;

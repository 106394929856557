import React from "react";
import Stars from "./Stars/Stars";
import Pages from "./Pages/Pages";
class Main extends React.Component {
  render() {
    return (
      <main>
        <Stars />
        <Pages />
      </main>
    );
  }
}

export default Main;

import React from "react";
import Main from "./Main/Main";
import Brand from "./Brand/Brand";
class Body extends React.Component {
  render() {
    return (
      <>
        <Brand />
        <Main />
      </>
    );
  }
}

export default Body;

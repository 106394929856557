import React from "react";
import { FaGooglePlay } from "react-icons/fa";

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageID: 0,
    };
  }

  componentDidMount() {}
  render() {
    let profileImg = this.props.bgPath + "brand.png";
    let button = "";
    let detailText = "";
    let isGooglePlay = this.props.googlePlay !== undefined;
    let gplink = "";
    if (isGooglePlay) {
      profileImg = "/engine/img/BrandImage/GooglePlay.png";
      gplink ="https://play.google.com/store/apps/details?id=" + this.props.googlePlay;
      button = (
        <a
          href={
            "https://play.google.com/store/apps/details?id=" +
            this.props.googlePlay
          }
          target="_blank"
          rel="noreferrer"
          className="card__save js-save"
          type="button"
        >
          <FaGooglePlay size={25} />
        </a>
      );
      detailText = "Google Play'den Elde Edebilirsiniz";
    } else {
      detailText = this.props.detailText;
    }

    return (
      (isGooglePlay)?(<div className="card" data-effect="zoom">
      {button}
      <figure className="card__image">
      <img src={this.props.bgPath + "bg.png"} alt="Short description" />
      </figure>
      <div className="card__header">
        <a
          href={gplink}
          target="_blank"
          rel="noreferrer"
          type="button"
          className="card__profile"
        >
        <figure className="card__profile">
          <img src={profileImg} alt="Short description" />
        </figure>
        </a>
      </div>
      <div className="card__body">
      <p className="card__bio"> {detailText}</p>
      </div>
    </div>):(<div className="card" data-effect="zoom">
      {button}
      <figure className="card__image">
        <img src={this.props.bgPath + "bg.png"} alt="Short description" />
      </figure>
      <div className="card__header">
        <figure className="card__profile">
          <img src={profileImg} alt="Short description" />
        </figure>
      </div>
      <div className="card__body">
        <p className="card__bio"> {detailText}</p>
      </div>
    </div>)
    );
  }
}

export default Card;

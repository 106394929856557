import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import $ from "jquery";

class Brand extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      let sumWidth = $(".BrandIcon").outerWidth() / 2;

      $(".BrandIcon").css({
        "margin-left": "-" + sumWidth + "px",
      });
    }, 100);
  }
  render() {
    return (
      <div className="BrandIcon colorGradiend-MidnightCity">
        <img alt="Geasis Brand Logo" src="/engine/img/logo_geasis.png" />
        <button>
          <FaInfoCircle size={20} />
        </button>
      </div>
    );
  }
}

export default Brand;
